.active-tab {
  border-bottom: 4px solid #ffc600;
  color: #ffc600;
}
.inactive-tab {
  color: white;
}
.filter-label-narrow {
  width: 80px;
  color: var(--form-input-label-color);
  margin-right: 5px;
  font-size: 13px;
  padding-left: 5px;
  padding-top: 7px;
}
.filter-label-x-narrow {
  width: 60px;
  color: var(--form-input-label-color);
  margin-right: 5px;
  font-size: 13px;
  padding-left: 5px;
  padding-top: 7px;
}
.k-splitter {
  background-color: var(--nav-bg);
}
.k-splitter .k-splitbar {
  background-color: #656565;
}
.filter-dropdown-wide {
  width: 400px;
  margin-right: 15px;
}
.filter-dropdown {
  width: 200px;
  margin-right: 15px;
}
.date-input {
  width: 200px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  height: 30px;
  border-radius: 2px;
  border: none;
}
.k-window-content,
.k-prompt-container {
  padding-block: 0;
  padding-inline: 0;
}

.k-input-value-text {
  overflow: hidden;
}

.k-window-titlebar {
  background-color: var(--modal-title-bg);
  border-color: var(--modal-title-border);
  color: var(--modal-icon);
}

/* .k-dialog-titlebar {
  background-color: rgb(0, 0, 0);
} */

.k-window-title {
  color: var(--modal-title-txt);
}

/* .k-window-content:last-child {
  padding-bottom: 0;
} */
.k-window-content {
  background-color: var(--modal-content-bg);
  padding: 0;
}

.kendo-notification-group {
  right: 0;
  top: 30px;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}

.movement-detail-title-label {
  width: 110px;
  text-align: center;
}

.tickets-page {
  padding: 4px;
  /* background-color: #a09d9d; */
  /* #303030;*/
}

.tickets-page .card-container .form-container {
  /* display: flex; */
  max-width: unset;
  /* background-color: rgb(48, 48, 48); */
  border-radius: 5px;
  padding: 5px;
}

.tickets-page .card-container .form-container .k-form .k-label {
  text-align: right;
  padding: 0;
  margin-right: 8px;
  font-weight: bold;
}

.movements-tickets-button-group {
  align-self: flex-end;
}

.movements-tickets-button-group .k-button {
  background-color: transparent;
  border-radius: unset;
  border-left: unset;
  border-top: unset;
  border-bottom: none;
  border-right: none;
  color: var(--nav-txt);
  min-width: 10rem;
  text-align: center;
  padding: 10px 2px;
  font-size: larger;
  margin: 1px;
}
.movements-tickets-button-group .k-button:hover {
  background-color: var(--nav-hover-bg);
}

.movements-tickets-button-group .k-button.k-selected {
  /* color: #ffc600; */
  box-shadow: 0 -3px 0 0 var(--shell-yellow) inset;
  background-color: var(--nav-selected-bg);
}

/* .movements-tickets-button-group .movements-tickets-button:hover {
  all: unset;
} */

.tickets-page .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  background-color: #222;
  border-bottom: 4px solid #222 !important;
  border-right: 1px solid white;
  color: #ffffff;
  min-width: 10rem;
  text-align: center;
  padding: 2px;
  font-size: medium;
}

.tickets-page .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  /* color: rgb(237, 28, 36);*/
  color: #ffc600;
  border-bottom: 4px solid #ffc600 !important;
  background-color: #222;
  /* background-color: #ffc600; */
  /* background-color:  */
}

.tickets-page .k-tabstrip > .k-content {
  background-color: rgb(34, 34, 34);
  padding: 4px;
}

.tickets-page .k-tabstrip > .k-content .k-animation-container {
  width: 100%;
}

.new-ticket-trigger-zone {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #303030;
  min-width: 20rem;
  max-width: 20rem;
  padding: 4px;
}

.new-ticket-trigger-zone .new-ticket-button {
  display: flex;
  flex-direction: column;
  background-color: #303030;
  color: white;
  width: 100px;
  margin: 2px;
}

.new-ticket-trigger-zone .new-ticket-button .k-icon {
  font-size: 4rem;
}

.new-ticket-trigger-zone .new-ticket-button .k-label {
  color: white;
  text-align: center;
}

/* .volume-allocation-modal .k-table-thead { */
.volume-allocation-modal .k-window-actions,
.volume-allocation-modal .k-grid-header {
  background-color: rgb(34, 34, 34);
}

/* .volume-allocation-modal .k-window-content {
  background-color: rgb(34, 34, 34);
  padding: 4px;
} */

.volume-allocation-modal .card-container .k-grid {
  padding: 0;
}

.volume-allocation-modal .card-container .k-grid .k-grid-toolbar {
  background-color: darkgray;
  padding: 4px;
  color: black;
}
