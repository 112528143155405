// Global variables here
$background-color: #222222;
$info-background: #151950;
$react-color: #53d2fa;
$link-color: #71a6ff;
$white: #ffffff;

$tiger-team: #ff6358;
$lemon-team: #ffd246;
$organic-team: #55ab1d;
$ocean-team: #28b4c8;

$primary: #fbce07 !default;

// Shell
$shell-red: #ed1c24;
$shell-yellow: #ffd500;
$shell-green: green;
$shell-image: url("../assets/shell-home-image.jpeg");

// Mac Dark Theme
$header-background-color: #34343c; //rgb(60, 60, 60);
$header-label-color: $white;

$drawer-background-color: black;
$drawer-item-color: gray;
$drawer-container-background-color: #222; //rgba(0, 0, 0, 0.5);

$card-background-color: #222; //#2f2f2f;
$card-text-color: #969696;
$title-background-color: black;
$title-label-color: #969696;
$border-color: rgb(49, 49, 49);

$form-field-background-color: #d4d4d4;
$form-label-color: lightgray;

$grid-background-color: #1c1f20;
$grid-text-color: #e7e7e7;
$grid-toolbar-background: #000033;
$grid-header-background: #1c1f20;
$grid-grouping-background: #2d3436;
$grid-master-row-background: #262c2e;
$grid-alt-row-backgound: #2d3436;
$grid-selected-background: rgba(83, 140, 230, 0.44);

$drawer-height: calc(100vh - 60px);
$drawer-content-height: calc(100vh - 184px);
$search-container-height: calc(100vh - 85px);
$grid-container-height: calc(100vh - 12px);
$grid-height: calc(100% - 2px);

$switch-handle-bgcolor: #c5c5c5;
$switch-on-bgcolor: $shell-yellow;
$switch-bgcolor-hover: #5e5d5d;

$success-msg-color: #a6ffab;
$error-msg-color: #ff6656;
$inedit-text-color: #ff6656;
