@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
}

body {
  font-family: "Roboto", sans-serif;
  // font-family: "Roboto Mono", monospace;
}

h1 {
  font-size: 1.25rem;
  font-weight: 400;
}

h2 {
  font-size: 1.25rem;
  font-weight: 200;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 100;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-block-start: 0.75rem;
  margin-block-end: 0.75rem;
}

a {
  font-size: 1rem;
  line-height: 1.25rem;
  color: inherit;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 200;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 200;
  }
}
