.forecast-upload-status {
  width: 800px;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem auto 1rem auto;
  justify-content: flex-end;
}
.forecast-upload-status > button {
  flex: 0 0 120px;
  justify-self: flex-end;
}

.k-grid .k-detail-row:hover {
  background: none;
}

.forecast-import-detail-grid {
  background-color: #ac3b3b;
}
.forecast-import-detail-grid .k-grid {
  margin-left: 0.5rem;
  font-size: smaller !important;
  max-height: 16rem;
}

.forecast-import-detail-grid .k-grid-header {
  background-color: #969696 !important;
  /* color: #1c1f20 !important; */
}

.forecast-import-detail-grid .k-master-row {
  background-color: whitesmoke !important;
  /* color: red !important; */
  /* color: #ff6358 !important */
  color: black !important;
}

.forecast-import-detail-grid .k-master-row.k-alt {
  background-color: #e7e7e7 !important;
}
.forecast-import-detail-grid .k-link:hover {
  cursor: default;
}
.forecast-import-detail-grid .k-grid tr:hover {
  background: none;
}

.forecast-import-detail-grid .k-grid tr.k-alt:hover {
  background: none;
}
