.ticket-search-form {
  display: grid;
  grid-template-columns: 120px minmax(120px, 1fr) 120px minmax(120px, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  margin-top: 2px;
  margin-left: 2px;
  align-items: center;
}

.ticket-search-form label {
  min-width: 80px;
  color: var(--form-field-txt-color);
}

.ticket-search-form input {
  min-width: 96px;
}

.ticket-search-form button {
  max-width: 96px;
  font-weight: bold;
}

.ticket-search-form .k-textbox:has(> #deliveryIds) {
  grid-column: 2/4;
}

.m-popup .k-list-item {
  font-size: 0.75rem;
}
