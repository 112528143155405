.stage {
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  margin: 0 -5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: gray;
}

.spinner {
  border: 16px solid silver;
  border-top: 16px solid #337ab7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
  animation-delay: 0.25s;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: gray;
  color: gray;
  box-shadow: 9999px 0 0 -5px gray;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin: auto;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: gray;
  color: gray;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px gray;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px gray;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px gray;
  }

  30% {
    box-shadow: 9984px 0 0 2px gray;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px gray;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px gray;
  }

  30% {
    box-shadow: 9999px 0 0 2px gray;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px gray;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px gray;
  }

  30% {
    box-shadow: 10014px 0 0 2px gray;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px gray;
  }
}
