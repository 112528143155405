.notification-page {
  .k-window-content {
    background-color: var(--nav-bg);
    padding: 0.5rem;
    color: #e0e0e0;
  }

  .k-dialog-buttongroup > .k-button {
    color: #e0e0e0;
  }

  p {
    font-size: 1rem;
    margin: 0;
  }

  .error-message {
    background-color: lightyellow;
    color: red;
    margin: 0 5px;
    padding: 5px;
    text-align: center;
  }

  .search-wrapper {
    margin: 4px;

    .card-container {
      background: $card-background-color;
      color: var(--card-text-color, $card-text-color);
      text-align: center;
      border-radius: 0;
      height: 100%;

      .card-title {
        padding: 10px;
        display: flex;
        background: black;
        color: $title-label-color;
        border-bottom: 1px solid $border-color;

        .card-title-label {
          color: $title-label-color;
          font-weight: bold;
          font-size: 16px;
        }

        .card-title-icon-group {
          display: flex;
          align-items: center;
          margin-left: auto;
        }

        .card-title-icon {
          margin-left: 15px;
        }
      }

      .card-message {
        background-color: white;
        color: red;
        padding: 10px;
      }

      .form-container {
        max-width: 1000px;
        padding: 0;
        margin: auto;

        .k-form,
        .k-form-horizontal {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          color: $title-label-color;

          .k-datepicker {
            width: 120px;
            margin: 0 0.1rem 0 0.1rem;
          }

          .radio-group-block {
            min-width: 256px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .radio-group-label {
              margin: 0;
            }

            .radio-group {
              display: flex;
              align-items: flex-end;
              justify-content: space-evenly;
            }

            input[type="radio"] {
              margin-left: 0.2rem;
            }
          }

          .form-item {
            .form-action-button {
              margin: 0 0.5rem;
              width: 60px;
            }
          }
        }
      }
    }
  }

  .content-wrapper {
    margin: 4px;

    .k-grid-header,
    .k-grouping-header,
    .k-grid-add-row,
    .k-grid-footer,
    .k-grid-toolbar {
      .k-switch {
        width: 90px;
      }

      .k-switch-label-on,
      .k-switch-label-off {
        text-align: center;
      }

      .k-switch-off .k-switch-handle {
        background-color: #eb5b51;
      }
    }

    .k-grid-content {
      background-color: $grid-master-row-background;
      color: white;
      overflow-y: visible;

      .k-master-row {
        &:hover {
          background-color: $grid-selected-background;
          color: var(
            --form-field-background-color,
            $form-field-background-color
          );
        }
      }

      .k-detail-row {
        background-color: var(
          --form-field-background-color,
          $form-field-background-color
        );
        color: black;
      }
    }

    .k-content .k-grid tr.k-alt {
      .k-detail-row .k-alt {
        background-color: var(
          --form-field-background-color,
          $form-field-background-color
        );
        color: black;
      }
    }

    .k-tabstrip-items .k-item.k-state-active,
    .k-tabstrip-items .k-item.k-state-selected {
      border-color: lightblue;
      color: black;
      background-color: orange;
    }

    .k-tabstrip-items .k-item {
      color: white;
    }

    .k-tabstrip > .k-content {
      border-color: rgba(0, 0, 0, 0.08);
      color: #656565;
      background-color: black;
      padding: 0px;
    }
  }

  .notification-detail-component {
    overflow: auto;
    max-height: 10rem;
    font-size: 0.75rem;
  }
}
