// This file contain the CSS variabels used for Light and Dark theme
// Always ensure both the mode contains exact same and equal number of variables
// In case of variable addition/removal , add/remove from both the varient's
:root {
  --black: #000000;
  --black-50-A: #1c1c1c0a;
  --black-100-A: #1c1c1c22;
  --black-200-A: #1c1c1c24;

  --grey-500: #757575;
  --grey-700: #4a4a4a;
  --grey-800: #343434;
  --grey-900: #292929;
  --grey-ver-1: #1c1f20;

  --white-100-A: #ffffff17; // 09%
  --white-400-A: #ffffff5a; // 35.4%
  --white-500-A: #ffffff7c; // 48.5%
  --white-800-A: #ffffffe9; // 91.5%
  --white-900-A: #fffffffd; // 99.5%
  --white: #ffffff;

  --red-500: #dd1d21;

  --yellow-200: #ffc600;
  --yellow-400: #b88a00;

  --sky-50: #eef6f9;
  --sky-temp50: #253436;

  --night-500: #4b78aa;

  --forest-500: #008557;

  --sunrise-200: #ffb15b;
  --sunrise-500: #b25f00;

  --violet-300: #c39ec9;
  --violet-600: #844b8e;

  --ocean: #0097a9;
  --ocean-high: #0097a91a; // 10%
  --ocean-300: #54b8c854; // 33%
  --ocean-400: #0097a9;
  --ocean-500: #008192;
  --ocean-medium: #0097a963; // 39%

  // Shell
  --shell-red: #ed1c24;
  --shell-yellow: var(--yellow-200);
  --shell-green: #008000;
}

.dark-mode {
  --bg-color: #222222;
  --drawer-container-background-color: var(--bg-color);

  --info-background: #151950;
  --react-color: #53d2fa;
  --link-color: #71a6ff;

  --tiger-team: #ff6358;
  --lemon-team: #ffd246;
  --organic-team: #55ab1d;
  --ocean-team: #28b4c8;

  //--primary: #fbce07 !default;

  --primary-btn-bg: var(--yellow-200);
  --primary-btn-txt: var(--grey-800);

  --primary-icon-color: var(--yellow-200);

  // Mac Dark Theme
  --header-background-color: #34343c;
  --header-label-color: var(--white);

  --drawer-background-color: var(--grey-900);
  --drawer-item-color: #808080;
  --drawer-container-background-color: var(--bg-color);

  --card-background-color: var(--bg-color);
  --card-text-color: var(--white-900-A);
  --title-background-color: #000000;
  --title-label-color: var(--card-text-color);
  --border-color: #313131;

  --form-field-background-color: #d4d4d4;
  --form-label-color: #d3d3d3;

  --grid-background-color: var(--grey-ver-1);
  --grid-text-color: #e7e7e7;
  --grid-toolbar-background: #000033;
  --grid-header-background: var(--grey-ver-1);
  --grid-grouping-background: #2d3436;
  --grid-master-row-background: #262c2e;
  --grid-alt-row-backgound: var(--grid-grouping-background);
  --grid-selected-background: rgba(83, 140, 230, 0.44);

  --switch-handle-bgcolor: #c5c5c5;
  --switch-on-bgcolor: var(--shell-yellow);
  --switch-bgcolor-hover: #5e5d5d;

  --success-msg-color: #a6ffab;
  --error-msg-color: #ff6656;
  --inedit-text-color: var(--error-msg-color);

  --drag-n-drop-bg: var(--white-100-A);
  --drag-n-drop-border: var(--white-400-A);
  --drag-n-drop-txt: var(--white-900-A);

  --link-bg-hover: #4d4d4d;

  --form-field-border-color: var(--white-400-A);
  --form-field-border-hover-color: var(--ocean);
  --form-field-bg-color: var(--grey-900);
  --form-field-txt-color: var(--white-900-A);

  --nav-txt: var(--white-900-A);
  --nav-bg: var(--grey-900);
  --nav-selected-bg: var(--white-100-A);
  --nav-selected-border: var(--shell-yellow);
  --nav-hover-bg: var(--ocean-high);

  --form-input-bg: var(--grey-900);
  --form-input-label-color: var(--white-900-A);
  --form-input-color: var(--white-900-A);
  --form-input-border: var(--white-400-A);
  --form-input-placeholder: var(--white-500-A);
  --form-input-icon: var(--white-900-A);

  --table-bg: var(--grey-800);
  --table-txt: var(--white-900-A);
  --table-head-border: var(--white-400-A);
  --table-border: var(--white-100-A);
  --table-row-hover-bg: var(--sky-temp50);

  --modal-title-bg: var(--grey-800);
  --modal-title-txt: var(--white-900-A);
  --modal-title-border: var(--white-400-A);
  --modal-icon: var(--white-800-A);
  --modal-content-bg: var(--grey-900);
  --modal-content-txt: var(--white-900-A);
  --modal-content-border: var(--white-900-A);

  --multiselect-bg: var(--grey-900);
  --multiselect-dropdown-bg: var(--grey-900);
  --multiselect-dropdown-txt: var(--white);
  --multiselect-dropdown-hover: var(--ocean-high);
  --multiselect-dropdown-selected-bg: var(--ocean-300);
  --multiselect-dropdown-selected-txt: var(--white);
  --multiselect-dropdown-selected-hover: var(--white-100-A);
  --multiselect-dropdown-badge-bg: var(--white-100-A);
  --multiselect-dropdown-badge-hover-bg: var(--ocean-high);
  --multiselect-dropdown-badge-txt: var(--white-900-A);
  --multiselect-dropdown-badge-icon: var(--white-500-A);
  --multiselect-dropdown-shadow: var(--black-200-A);
  --checkbox-label-txt: var(--white-900-A);

  --toggle-off-bg: var(--white-400-A);
  --toggle-on-bg: var(--ocean-400);

  --toggle-off-txt: var(--white);
  --toggle-on-txt: var(--white);

  --env-badge-development-color: var(--night-500);
  --env-badge-uat-color: var(--sunrise-200);
  --env-badge-sbx-color: var(--violet-300);
  --env-badge-dev-color: var(--night-500);
  --env-badge-sds-color: var(--forest-500);

  --env-badge-text-color: var(--grey-800);
  --submenu-menu-group-title-color: var(--white-500-A);
  --dashboard-card-icon-color: var(--white-500-A);
}

.light-mode {
  --bg-color: var(--white);
  --drawer-container-background-color: var(--white);

  --info-background: #151950;
  --react-color: #53d2fa;
  --link-color: #71a6ff;

  --tiger-team: #ff6358;
  --lemon-team: #ffd246;
  --organic-team: #55ab1d;
  --ocean-team: #28b4c8;

  //--primary: #fbce07 !default;

  --primary-btn-bg: var(--yellow-200);
  --primary-btn-txt: var(--grey-800);

  --primary-icon-color: var(--yellow-400);

  // Mac Dark Theme
  --header-background-color: var(--white);
  --header-label-color: var(--black);

  --drawer-background-color: var(--white);
  --drawer-item-color: #808080;
  --drawer-container-background-color: var(--bg-color);

  --card-background-color: var(--bg-color);
  --card-text-color: var(--grey-800);
  --title-background-color: #000000;
  --title-label-color: var(--card-text-color);
  --border-color: #313131;

  --form-field-background-color: var(--white);
  --form-label-color: #d3d3d3;

  --grid-background-color: var(--white);
  --grid-text-color: #e7e7e7;
  --grid-toolbar-background: #000033;
  --grid-header-background: var(--white);
  --grid-grouping-background: #2d3436;
  --grid-master-row-background: #262c2e;
  --grid-alt-row-backgound: var(--grid-grouping-background);
  --grid-selected-background: rgba(83, 140, 230, 0.44);

  --switch-handle-bgcolor: #c5c5c5;
  --switch-on-bgcolor: var(--shell-yellow);
  --switch-bgcolor-hover: #5e5d5d;

  --success-msg-color: #a6ffab;
  --error-msg-color: #ff6656;
  --inedit-text-color: var(--error-msg-color);

  --drag-n-drop-bg: var(--black-50-A);
  --drag-n-drop-border: var(--black-100-A);
  --drag-n-drop-txt: var(--grey-800);

  --link-bg-hover: #cccccc;

  --form-field-border-color: var(--black-200-A);
  --form-field-border-hover-color: var(--ocean);
  --form-field-bg-color: var(--white);
  --form-field-txt-color: var(--grey-800);

  --nav-txt: var(--grey-800);
  --nav-bg: var(--white);
  --nav-selected-bg: var(--black-50-A);
  --nav-selected-border: var(--shell-yellow);
  --nav-hover-bg: var(--ocean-high);

  --form-input-bg: var(--white);
  --form-input-label-color: var(--grey-800);

  --form-input-color: var(--grey-800);
  --form-input-border: var(--black-100-A);
  --form-input-placeholder: var(--grey-500);
  --form-input-icon: var(--grey-800);

  --table-bg: var(--white);
  --table-txt: var(--grey-800);
  --table-head-border: var(--black-100-A);
  --table-border: var(--black-50-A);
  --table-row-hover-bg: var(--sky-50);

  --modal-title-bg: var(--white);
  --modal-title-txt: var(--grey-800);
  --modal-title-border: var(--black-200-A);
  --modal-icon: var(--grey-900);
  --modal-content-bg: var(--white);
  --modal-content-txt: var(--grey-800);
  --modal-content-border: var(--black-50-A);

  --multiselect-bg: var(--white);
  --multiselect-dropdown-bg: var(--white);
  --multiselect-dropdown-txt: var(--grey-800);
  --multiselect-dropdown-hover: var(--black-50-A);
  --multiselect-dropdown-selected-bg: var(--ocean-medium);
  --multiselect-dropdown-selected-txt: var(--grey-800);
  --multiselect-dropdown-selected-hover: var(--black-50-A);
  --multiselect-dropdown-badge-bg: var(--black-50-A);
  --multiselect-dropdown-badge-hover-bg: var(--ocean-high);
  --multiselect-dropdown-badge-txt: var(--grey-800);
  --multiselect-dropdown-badge-icon: var(--grey-500);
  --multiselect-dropdown-shadow: var(--black-50-A);
  --checkbox-label-txt: var(--grey-800);

  --toggle-off-bg: var(--black-100-A);
  --toggle-on-bg: var(--ocean-400);

  --toggle-off-txt: var(--ocean-400);
  --toggle-on-txt: var(--white);

  --env-badge-development-color: var(--night-500);
  --env-badge-uat-color: var(--sunrise-500);
  --env-badge-sbx-color: var(--violet-600);
  --env-badge-dev-color: var(--night-500);
  --env-badge-sds-color: var(--forest-500);

  --env-badge-text-color: var(--white-900-A);
  --submenu-menu-group-title-color: var(--grey-500);
  --dashboard-card-icon-color: var(--grey-500);
}
