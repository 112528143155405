.card-wrapper {
  display: flex;
  align-items: flex-start;
  .card {
    width: 150px;
    padding: 10px;
    margin: 10px 10px 0 0;
    background-color: #292929;
    border-radius: 2px;
    cursor: pointer;
    .card-icon {
      width: 100%;
      height: 60px;
      :hover {
        fill: #ffc600;
      }
    }
    .card-label {
      width: 100%;
      text-align: center;
      min-height: 48px;
    }
    &:hover {
      .card-icon {
        fill: #ffc600;
      }
    }
  }
}

.carbon-section-heading {
  padding: 20px 10px 10px !important;
}
.loading-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

.customer-Search {
  cursor: pointer;
  margin-left: 30px;
  vertical-align: middle;
  &:hover {
    svg {
      fill: #ffc600;
    }
  }
}

.from-button-wrap {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 12px;
}
.customer-Search-reset {
  cursor: pointer;
  color: #4b78aa;
  font-size: 14px;
  line-height: 20px;
  margin: 0 7px;
  border: 1px solid #4b78aa;
  border-radius: 20px;
  padding: 0px 6px;
  background-color: transparent;
  > * {
    vertical-align: middle;
  }
  > svg {
    vertical-align: middle;
    height: 18px;
    margin-bottom: 5px;
    margin-right: 8px;
  }
}
.customer-Search-current-filter {
  margin: 0 7px;
  display: inline-flex;
}
.customer-Search-current-filter-txt {
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 16px;
  background-color: #4b78aa;
  border-radius: 20px;
  margin: 0 3px;
}
.customer-update-button {
  color: white;
  width: 90px;
  height: 35px;
  font-size: large;
  border-radius: 7px;

  &.add {
    height: 70px;
    width: 130px;
    float: right;
    margin-right: 5px;
    background-color: rgb(50, 110, 180);
  }
  &.cancel {
    background-color: red;
  }
  &.save {
    background-color: green;
  }
}
.customer-save-button {
  background-color: rgb(255, 198, 0);
  width: 90px;
  height: 40px;
  border-radius: 7px;
  font-size: large;
}

.form-flex-container {
  display: flex;
  justify-content: space-between;
  .left {
    width: 45%;
  }
  .right {
    position: relative;
    margin-right: 20px;
    width: 45%;
  }
}

.delete-icon {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 43%;
  z-index: 1;
}

.common-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
}

.header-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  > * {
    cursor: pointer;
  }
}

.cta-button-wrapper {
  display: flex;
  justify-content: space-between;
  > * {
    margin: 0 5px;
  }
}

.carboniq-data-table {
  width: 100%;
  tr:has(.editedRow) {
    box-shadow: 0 0 0 2px #ff542f57 inset;
  }
}

.common-error-container.common-error-container {
  position: relative;
  padding: 0;
  margin-bottom: 15px;
  .close-icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 7px);
    cursor: pointer;
  }
}
.weighted-info-wrap {
  position: relative;
}

.weighted-info {
  position: absolute;
  right: 0;
  top: 0;
}
.savings-details-table {
  border: 1px solid var(--table-border);
  background-color: var(--table-bg);
  color: var(--table-txt);
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  border-spacing: 0;
  td,
  th {
    padding: 2px;
  }
}

.product-row-highlight {
  background-color: var(--table-row-hover-bg);
}
