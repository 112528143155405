.subscription-page {
  border-top: 4px solid gray;
  font-size: 0.85rem;
}

.subscriptions-container {
  display: flex;
  padding: 8px 2px;
  gap: 8px;
}

.services-tree {
  background-color: #2d3436;
  font-size: 0.75rem;
}

.subscriptions-item {
  flex: 1 1 auto;
}

.subscriptions-item:last-child {
  flex-basis: 80%;
}

.subscription-page .k-treeview,
.subscription-page .k-content {
  /* background-color: #2d3436; */
  color: var(--card-text-color);
  font-size: 0.85rem;
  border: 1px solid var(--modal-title-border);
  padding: 4px;
}

.k-treeview .k-in:hover,
.k-treeview .k-in.k-state-hover {
  background-color: rgb(255, 99, 98);
  color: #fff;
  cursor: pointer;
}

.k-treeview .k-in.k-state-focus,
.k-treeview .k-in.k-state-focused,
.k-treeview .k-in.k-state-selected {
  color: #fff;
  box-shadow: inset 0 0 0 2px rgb(0 0 0/5%);
  background-color: rgb(255, 99, 98);
}

.k-treeview ul {
  padding: 0;
}

.k-grid tbody tr:not(.k-detail-row):hover,
.k-grid tbody tr:not(.k-detail-row).k-state-hover {
  background-color: rgb(255, 99, 98);
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: rgba(255, 99, 88, 0);
}

.error-message {
  background-color: lightyellow;
  color: red;
  padding: 10px;
  margin: 5px;
  text-align: center;
}

.subscription-page .card-container .k-grid {
  font-size: 0.85rem;
}

/* .subscription-page .k-button {
    background-color: rgb(255, 99, 98);
    color: #fff
} */

.k-loading-mask {
  z-index: 10004;
}
