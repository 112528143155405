// Normalization
body {
  background-color: var(--bg-color);
}

body,
h1,
h2,
h3 {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

a {
  text-decoration: none;
}

.k-overlay {
  background-color: gray;
  opacity: 0.8;
}

.error-message {
  background-color: lightyellow;
  color: red;
  padding: 10px;
  margin: 5px;
  text-align: center;
  overflow-wrap: break-word;
  border-radius: 5px;
}

.file-too-large {
  margin: 0;
}

//==================
// Header
//==================
.header {
  position: sticky;
  padding: 10px 0;
  top: 0;
  z-index: 3;
  background-color: var(--header-background-color, $header-background-color);
  border-bottom: 3px solid $shell-yellow;

  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .menu-button {
    flex: 0 0 50px;
    text-align: center;
  }

  .hamburger-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .title {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    color: var(--header-label-color, $header-label-color);
    font-size: 20px;
  }
  .mack-version {
    color: var(--header-background-color);
    margin: 0 5px;
    font-size: 0.5rem;
  }

  .vl {
    border-left: 1px solid #fff;
    height: 20px;
    margin: 6px 15px;
  }

  .k-avatar {
    display: block;
    width: 35px;
    height: 35px;
    margin: 0 25px;
    box-shadow: 0 0 0 1px $white;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .settings {
    display: none;
  }

  .img {
    width: 30px;
    margin: 0 10px;
  }

  .logo {
    width: 35px;
    margin: 0 4px 0 0;
  }
}

.theme-switch {
  &.k-switch {
    width: 120px;
    font-weight: bold;
  }
}

.hidden.hidden.hidden.hidden.hidden.hidden {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 480px) {
  .header {
    .title {
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
    }

    .vl {
      border-left: 1px solid $header-label-color;
      height: 20px;
      margin: 6px 15px;
    }
  }
}

@media (min-width: 768px) {
  .header {
    .settings {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }

    .k-dropdown {
      margin-left: 15px;
    }
  }
}

//=======================
// Menu Drawer Container
//=======================

.mk-bulk-import-icon::before {
  content: "\e909";
  font-size: 1.25rem !important;
}

.k-drawer-container {
  background-color: var(
    --drawer-container-background-color,
    $drawer-container-background-color
  );
  min-height: $drawer-height;

  .k-drawer {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-right-width: 0 !important;
    background-color: var(--nav-bg);
  }

  &.k-drawer-expanded {
    .k-drawer-wrapper {
      width: 240px !important;
    }
    .k-drawer-content {
      width: calc(100vw - 240px);
    }
  }

  & .k-drawer-wrapper {
    width: 50px !important;
    opacity: 1;
    transition: all 200ms ease 0s;
  }

  .k-drawer-content {
    overflow-y: auto;
    width: calc(100vw - 50px);
  }

  .k-drawer-item {
    color: var(--nav-txt);
    padding: 0 10px;

    &.k-drawer-separator {
      background-color: #cacaca;
    }

    &:not(.k-drawer-separator) {
      padding: 10px;
    }

    &.k-selected.k-selected {
      background-color: var(--nav-selected-bg);
      box-shadow: -3px 0 0 0 var(--nav-selected-border) inset;
    }

    &:hover {
      background-color: var(--nav-hover-bg);
      color: var(--nav-txt);
    }

    &.sub-menu {
      align-items: center;

      .k-icon {
        width: 12px;
        height: 18px;
        margin-left: 12px;
      }

      .k-item-text {
        font-size: 0.75rem;
        color: var(--card-text-color);
      }
    }
  }
}

.k-table-thead {
  color: var(--table-txt);
  background-color: var(--table-bg, $grid-header-background);
  padding: 0 !important;
}

#ticketing > .react-pdf__Document {
  max-height: calc(100vh - 200px);
}

//=======================
// Card Container
//=======================
.card-container {
  background-color: var(--bg-color, $card-background-color);
  color: var(--card-text-color, $card-text-color);
  text-align: center;
  border-radius: 0;

  .card-title {
    padding: 10px;
    display: flex;
    background-color: var(--bg-color);
    color: $title-label-color;
    border-bottom: 1px solid $border-color;

    .card-title-label {
      color: $title-label-color;
      font-weight: bold;
      font-size: 16px;
    }

    .card-title-icon-group {
      //position: absolute;
      //right: 20px;
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .card-title-icon {
      margin-left: 8px;
    }
  }

  .card-message {
    background-color: white;
    color: red;
    padding: 10px;
  }

  .form-container {
    max-width: 800px;
    padding: 0;
    margin: auto;

    .k-form {
      .k-form-field {
        color: var(--card-text-color, $card-text-color);
        flex: 1;
        padding: 0 20px;
        align-items: center;
        margin: 12px 0;
        .k-floatwrap,
        .k-dateinput-wrap,
        .k-textbox {
          background-color: var(--form-field-bg-color);
          color: var(--form-field-txt-color);
          border-color: var(--form-field-border-color);
          &:hover {
            border-color: var(--form-field-border-hover-color);
          }
        }

        .k-multiselect-wrap {
          background-color: var(--form-field-bg-color);
          color: var(--form-field-txt-color);
          border-color: var(--form-field-border-color);
          &:hover {
            border-color: var(--form-field-border-hover-color);
          }
          .k-item.k-state-selected {
            background-color: $grid-selected-background;
          }
        }
      }

      .k-label {
        text-align: right;
        padding: 0;
        margin-right: 20px;
        font-weight: bold;
      }

      .k-label-empty {
        width: 0;
        padding: 0;
        margin: 0 -20px 0 0;
      }

      .k-form-button {
        display: flex;
        align-items: center;
        padding: 12px;
      }

      .k-switch {
        height: 24px;
      }

      .k-switch-handle {
        width: 24px;
        height: 24px;
      }

      .k-switch-on .k-switch-handle {
        left: calc(100% - 24px);
      }

      .k-switch-off .k-switch-handle {
        background-color: #eb5b51;
      }
    }

    .form-action-button {
      width: 120px;
      margin-left: 10px;
    }
  }

  .export-form-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 4px;
    gap: 4px;
  }

  .k-grid {
    border: 0;
    font-size: 0.85rem;

    .k-table {
      border-collapse: collapse;
    }

    .k-i-error {
      color: $error-msg-color;
    }

    .k-link {
      white-space: break-spaces;
      text-align: left;
    }

    .k-button {
      height: 25px;
    }

    .k-grouping-row td {
      color: #59cafd;
      background-color: #1c1c1c;
      border-bottom: 1px solid #2b2b2b;
    }

    .k-grouping-header {
      background-color: $grid-grouping-background;
      color: $title-label-color;
      border-bottom: 1px solid var(--table-border, $border-color);
    }

    .k-grid-header {
      background-color: var(--table-bg);
      color: $grid-text-color;
      border-bottom: 1px solid var(--table-border, $border-color);

      .k-header {
        text-align: left;
      }
    }

    .k-grid-content {
      background-color: var(--table-bg);
    }

    .k-grid-toolbar {
      margin: 0;
      padding: 0;
      border: 0;
      background: var(--modal-title-bg);
      border-bottom: 1px solid var(--modal-title-border);
      justify-content: flex-end;

      .grid-tool-error-msg {
        color: $error-msg-color;
        width: auto;
        font-size: 0.8rem;
        margin-right: 1rem;
      }
    }

    .k-master-row {
      background-color: var(--table-bg, $grid-master-row-background);
      color: var(--table-txt, $grid-text-color);
      border-bottom: 1px solid var(--table-border);
    }

    .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
    .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
      background-color: var(--table-row-hover-bg, #666);
    }

    .k-grid-header-table {
      .k-table-thead {
        > .k-table-row {
          border-bottom: 1px solid var(--table-head-border);
          th {
            border-color: var(--table-head-border);
          }
        }
      }
    }

    .k-column-title {
      white-space: unset;
    }

    .k-column-resizer {
      border-right: 0.5px solid #555;
    }

    .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
    .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
      background-color: var(--table-row-hover-bg);
    }

    .k-column-title {
      white-space: unset;
    }

    .k-column-resizer {
      border-right: 0.5px solid #555;
    }

    th,
    td {
      padding: 4px;
      border-color: var(--table-border);
    }

    th.k-header[role="presentation"] {
      //display: none;
      background-color: $grid-header-background;
    }

    .k-group-cell {
      //display: none;
      background-color: $grid-header-background;
    }

    .k-pager-wrap {
      //background-color: black;
      //color: $title-label-color;
      //border-color: $border-color;
      height: 45px;
    }
  }

  .group-container {
    height: $grid-container-height;
    overflow: auto;

    .k-treeview {
      color: $grid-text-color;
      font-size: 12px;

      .k-treeview-lines {
        padding-inline-start: 10px !important;
      }
    }

    .k-content {
      max-height: 200px;
      margin: 0 10px 15px 0;
      padding: 5px;
      overflow: auto;
    }

    .k-item {
      color: $grid-text-color;
    }

    .k-in.k-state-selected {
      background-color: $shell-yellow;
    }
  }

  .search-container {
    padding: 10px;
    border-bottom: 1px solid $border-color;

    .input-field {
      width: 100%;
      background-color: #424242;
      color: white;
    }
  }

  .filter-container {
    padding: 10px;
    height: 100%;
    font-size: 12px;

    .filter-section {
      margin-bottom: 20px;
    }

    .filter-form {
      border: 1px solid;
    }

    .checklist-header {
      color: $grid-text-color;
      font-size: 14px;
    }

    .checklist {
      padding: 10px;
      margin-top: 5px;
      max-height: 100px;
      overflow: auto;
    }

    .k-checkbox {
      width: 10px;
      height: 10px;
      border-radius: 0;
      margin-right: 10px;
    }

    .k-checkbox:checked {
      border-color: $shell-yellow;
      color: #ffffff;
      background-color: $shell-yellow;
    }

    .k-checkbox-label {
      color: $grid-text-color;
      font-size: 12px;
    }

    .k-columnmenu-item {
      font-size: 14px;
      font-weight: bold;
      color: lightgray;
      margin-bottom: 5px;
      padding-left: 5px;
    }

    .k-columnmenu-item:hover {
      background-color: var(--link-bg-hover);
    }

    .k-column-list-item:hover {
      background-color: var(--link-bg-hover);
    }
  }

  &.search {
    height: auto;
  }

  &.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 1em;
  }

  .card-buttons {
    .k-button {
      min-width: 105px;
    }

    .k-state-active {
      background-color: rgb(221, 29, 33);
      border-color: #dd1d21;
    }
  }

  .card-component {
    grid-column: 1 / -1;
    overflow: hidden;
  }
}

@media (min-width: 480px) {
  .card-container {
    text-align: initial;
    //padding: 10px;

    &.grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-title {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    .card-buttons {
      text-align: center;
      grid-column: 1 / -1;
      grid-row: 2;
    }
  }
}

@media (min-width: 768px) {
  .card-container {
    //margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &.grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .card-buttons {
      text-align: center;
      grid-column: 2 / 3;
      grid-row: 1;
    }

    .card-component {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
}

//================
// Home Page
//================
.home-page {
  background-image: url("./../shared/assets/shell-home-image.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  height: $drawer-content-height;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 28px;
  padding-top: 120px;
  font-family: sans-serif;
}

//================
// Forecast page
//================
.forecast-page {
  padding: 0;
  margin: 0;

  .card-container:last-of-type {
    padding-top: 0;
  }

  .search-wrapper {
    margin: 5px 5px 0 5px;
    /* position: sticky; top: 64px; z-index: 4;*/
    //height: $search-container-height;
  }

  .content-wrapper {
    margin: 0 5px 5px 5px;
  }

  .form-error {
    margin: 0 5px;
    padding: 5px;
    text-align: center;
    background-color: white;
    color: red;
  }

  .grid-tool-bar {
    display: flex;
    padding: 2px 10px;
    border-bottom: 1px solid #575499;

    .grid-tool-msg {
      color: $success-msg-color;
      width: auto;
      font-size: 14px;
      margin: 10px 0;
    }

    .grid-tool-buttons {
      padding-top: 3px;
      margin-left: auto;
    }

    .k-switch {
      width: 90px;
      margin-left: 4px;
    }

    .k-switch-handle {
      width: 24px;
      height: 24px;
    }

    .k-switch-on .k-switch-handle {
      left: calc(100% - 24px);
    }

    .k-switch-off {
      color: red;
    }

    .k-switch-label-on,
    .k-switch-label-off {
      text-align: center;
    }

    .k-switch-off .k-switch-handle {
      background-color: #eb5b51;
    }
  }

  .toolbar-bulk-editor {
    margin-right: 10px;

    .k-button {
      height: 24px;
      width: 190px;
      padding: 0 8px;
      margin-left: 5px;
      font-size: 13px;
      border-radius: 15px;
    }
  }

  .form-container {
    .k-multiselect {
      & ::selection {
        background-color: $shell-yellow;
      }
    }

    .k-switch {
      width: 70px;
    }

    .k-switch-on:hover .k-switch-container {
      background-color: $switch-bgcolor-hover;
    }

    .k-switch-off:hover .k-switch-container {
      color: white;
      background-color: $switch-bgcolor-hover;
    }
  }

  .grid-wrapper {
    display: flex;

    .grid-container {
      overflow: hidden;
      flex: 1 1 auto;
      width: 0;
      background-color: var(--table-bg);

      .k-splitter {
        height: 100%;
        border: 0;
        background-color: $grid-background-color !important;

        .k-scrollable {
          overflow: hidden;
        }
      }
    }

    .grid-tool {
      display: flex;
      background-color: var(--nav-bg);
      position: relative;
      height: $grid-container-height;
      padding-right: 10px;

      .grid-filter {
        width: 250px;
        overflow: scroll;
        border-right: 1px solid $border-color;
      }

      .grid-group {
        width: 250px;
        overflow: scroll;
        border-right: 1px solid $border-color;
      }

      .tool-bar {
        background: $title-background-color;
        color: $grid-text-color;
        width: 35px;
        //border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;

        .tool-bar-text {
          writing-mode: vertical-lr;
          transform: rotate(360deg);
          text-orientation: sideways;
          color: $grid-text-color;
        }

        .k-button {
          width: 35px;
          height: 80px;
          border-radius: 0;
          background: $title-background-color;
        }

        .k-button:hover {
          background-color: var(--link-bg-hover);
        }
      }
    }
  }

  .edited-text {
    color: $inedit-text-color;
  }

  .msg-bar {
    background: white;
    height: 30px;
    padding: 0 10px;
    border-left: 1px solid black;

    .msg-text {
      padding-top: 8px;
    }
  }

  .msg {
    width: 70%;
    margin: auto;
    border: 1px solid white;
    text-align: center;
    margin-top: 100px;
    padding: 60px;
    background: white;
    color: red;
  }
}

//=================================
// Bulk import page
//=================================
.bulk-import-tickets-page {
  padding: 0;
  margin: 0;

  .card-container:last-of-type {
    padding-top: 0;
  }

  .search-wrapper {
    margin: 5px 5px 0 5px;
    /* position: sticky; top: 64px; z-index: 4;*/
    //height: $search-container-height;
  }

  .content-wrapper {
    margin: 2px;
  }

  .form-error {
    margin: 0 5px;
    padding: 5px;
    text-align: center;
    background-color: white;
    color: red;
  }

  .grid-tool-bar {
    display: flex;
    background: #000033;
    padding: 2px 10px;
    border-bottom: 1px solid #575499;

    .grid-tool-msg {
      color: $success-msg-color;
      width: auto;
      font-size: 14px;
      margin: 10px 0;
    }

    .grid-tool-buttons {
      padding-top: 3px;
      margin-left: auto;
    }

    .k-switch {
      width: 90px;
      margin-left: 4px;
    }

    .k-switch-handle {
      width: 24px;
      height: 24px;
    }

    .k-switch-on .k-switch-handle {
      left: calc(100% - 24px);
    }

    .k-switch-off {
      color: red;
      background-color: #7f7f7f;
    }

    .k-switch-label-on,
    .k-switch-label-off {
      text-align: center;
    }

    .k-switch-off .k-switch-handle {
      background-color: #eb5b51;
    }
  }

  .toolbar-bulk-editor {
    margin-right: 10px;

    .k-button {
      height: 24px;
      width: 190px;
      padding: 0 8px;
      margin-left: 5px;
      font-size: 13px;
      border-radius: 15px;
    }
  }

  .form-container {
    .k-multiselect {
      & ::selection {
        background-color: $shell-yellow;
      }
    }

    .k-switch {
      width: 70px;
    }

    .k-switch-on:hover .k-switch-container {
      background-color: $switch-bgcolor-hover;
    }

    .k-switch-off:hover .k-switch-container {
      color: white;
      background-color: $switch-bgcolor-hover;
    }
  }

  .grid-wrapper {
    display: flex;
    //margin: 10px;

    .grid-container {
      overflow: hidden;
      flex: 1 1 auto;
      width: 0;
      background-color: $grid-background-color;
      border-left: 1px solid $border-color;

      .k-splitter {
        height: 100%;
        border: 0;
        background-color: $grid-background-color !important;

        .k-scrollable {
          overflow: hidden;
        }
      }

      .card-container {
        .k-grid {
          font-size: 0.75rem;

          .k-link {
            justify-content: center;

            .k-column-title {
              white-space: break-spaces;
              text-align: center;
            }
          }

          .k-master-row:hover {
            background-color: var(--table-row-hover-bg);
          }

          .k-grid-edit-row {
            td {
              padding: 2px;
            }
          }

          .bt-i-warning:before {
            content: "\26a0";
            /* Adds a glyph using the Unicode character number */
          }

          .bt-i-error:before {
            content: "\26d4";
            /* Adds a glyph using the Unicode character number */
          }

          .k-dateinput-wrap {
            font-size: 0.75rem;

            input {
              padding: 2px;
            }
          }

          .k-grid-edit-cell {
            input {
              font-size: 0.75rem;
            }
          }

          .k-numeric-wrap {
            input {
              font-size: 0.75rem;
            }
          }

          //k-color-error
          //8861

          .k-grid-header {
            .k-header {
              border-color: darkslategray;
            }
          }

          .k-table-md {
            font-size: 0.75rem;
          }
        }
      }
    }

    .grid-tool {
      display: flex;
      background-color: var(--nav-bg);
      position: relative;
      height: $grid-container-height;
      //padding-left: 10px;
      padding-right: 10px;

      .grid-filter {
        width: 250px;
        overflow: scroll;
        border-right: 1px solid $border-color;
      }

      .grid-group {
        width: 250px;
        overflow: scroll;
        border-right: 1px solid $border-color;
      }

      .tool-bar {
        background: $title-background-color;
        color: $grid-text-color;
        width: 35px;
        //border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;

        .tool-bar-text {
          writing-mode: vertical-lr;
          transform: rotate(360deg);
          text-orientation: sideways;
          color: $grid-text-color;
        }

        .k-button {
          width: 35px;
          height: 80px;
          border-radius: 0;
          background: $title-background-color;
        }

        .k-button:hover {
          background-color: var(--link-bg-hover);
        }
      }
    }
  }

  .edited-text {
    color: $inedit-text-color;
  }

  .txt-preserve-whitespace {
    white-space: pre;
  }

  .msg-bar {
    background: white;
    height: 30px;
    padding: 0 10px;
    border-left: 1px solid black;

    .msg-text {
      padding-top: 8px;
    }
  }

  .msg {
    width: 70%;
    margin: auto;
    border: 1px solid white;
    text-align: center;
    margin-top: 100px;
    padding: 60px;
    background: white;
    color: red;
  }
}

#mccShipCode {
  font-size: 0.75rem;

  .k-dropdowngrid-popup {
    font-size: 0.75rem !important;
  }
}

#pdfViewerDialog {
  background-color: #e0e0e0;
  padding: 4px 2px;

  & > .k-window-title {
    color: #000033;
    font-size: 0.85rem;
  }

  + .k-dialog-content {
    background-color: white;
    padding: 4px;
    max-height: calc(100vh - 80px);

    .react-pdf__Document {
      position: relative;

      &:hover {
        .page-controls {
          opacity: 1;
        }
      }
    }

    .page-controls {
      position: absolute;
      bottom: 5%;
      left: 50%;
      background: white;
      opacity: 0;
      transform: translateX(-50%);
      transition: opacity ease-in-out 0.2s;
      box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
      z-index: 10001;

      span {
        font: inherit;
        font-size: 0.8em;
        padding: 0 0.5em;
      }

      button {
        width: 44px;
        height: 44px;
        background: white;
        border-radius: 4px;
        border: 0;
        font: inherit;
        font-size: 0.8em;

        &:enabled {
          &:hover {
            cursor: pointer;
          }

          &:hover,
          &:focus {
            background-color: #e6e6e6;
          }
        }

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

//==================
// Dashboard
//==================
.dashboard-page {
  .card-container:last-of-type {
    padding-top: 0;
  }

  .k-grid-toolbar .k-textbox {
    margin-bottom: 10px;
  }

  .k-button-active.k-state-active {
    background-color: $shell-red;
  }
}

@media (min-width: 480px) {
  .dashboard-page {
    .k-grid-toolbar .k-textbox {
      margin-bottom: 0;
      float: left;
    }

    .k-grid-toolbar .k-button {
      float: right;
    }
  }
}

@media (min-width: 768px) {
  .dashboard-page {
    .card-container:last-of-type {
      padding-top: 30px;
    }
  }
}

//================
// Planning page
//================
.planning-page {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-title {
    grid-column: 1 / -1;
  }

  .k-hbox {
    padding: 0 20px;
    background: transparent;
  }

  .k-avatar {
    width: 41px;
    height: 41px;
  }

  .k-card-title {
    font-size: 15px;
    margin-bottom: -6px;
    text-align: left;
  }

  .k-card-subtitle {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 11px;
  }

  .disabled {
    opacity: 0.5;
  }
}

@media (min-width: 480px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: 768px) {
  .planning-page {
    .k-hbox {
      padding: 0;
      border-width: 0;
    }
  }
}

@media (min-width: 960px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 1360px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

//================
// Profile page
//================
.profile-page {
  .card-container {
    text-align: left;
  }

  .k-form .k-form-field {
    display: block;
    padding: 0 15px;
  }

  .k-form-field:first-of-type .k-form-field-wrap {
    margin: auto;
  }

  .k-form-field:first-of-type .k-avatar {
    margin: 0 auto 1rem;
  }

  .k-form.k-form-horizontal .k-label {
    width: 100%;
  }

  hr {
    margin-top: 1rem;
    opacity: 0.2;
  }

  .k-form .k-form-buttons {
    justify-content: center;

    & > * {
      min-width: 140px;
    }
  }
}

@media (min-width: 480px) {
  .profile-page {
    .k-form .k-form-field {
      display: flex;
    }

    .k-form-field:first-of-type .k-avatar {
      margin: 0;
    }

    .k-form.k-form-horizontal .k-label {
      width: 25%;
    }
  }
}

@media (min-width: 768px) {
  .profile-page {
    .k-form {
      margin: 0 auto;
      padding: 0;
    }

    .k-form .k-form-field {
      padding: 0;
    }
  }
}

//================
// Info page
//================
.info-page {
  background-color: $info-background;
  color: $white;

  h1,
  h2,
  h3,
  .component-link {
    font-family: Metric, Helvetica, Arial, sans-serif;
  }

  a:not(.k-button) {
    color: $link-color;
  }

  .content {
    padding-top: 40px;
    margin: 0 auto;
    position: relative;
    max-width: 820px;
  }

  .kendoka {
    text-align: center;

    img {
      max-width: 280px;
    }
  }

  .section-1 {
    text-align: center;

    h1 {
      font-size: 48px;
      font-weight: bold;
      color: $react-color;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 53px;
    }

    .button-group {
      margin-bottom: 30px;

      .k-button {
        display: block;
        margin: 10px auto;
        min-width: 145px;
        width: 60%;
      }
    }

    .github-link {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      align-content: center;

      .github-text {
        margin-left: 15px;
      }
    }
  }

  .section-2 {
    text-align: center;
    margin-top: 40px;
    padding: 0 15px;
  }

  .section-3 {
    -webkit-columns: 2;
    column-count: 2;
    margin-top: 40px;
    padding: 0 15px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .package-item {
    display: inline-block;
    margin-top: 20px;
  }

  .package-title a {
    font-size: 17px;
    color: #cccccc;
    text-transform: uppercase;
  }

  .component-link {
    padding: 5px 0;

    a {
      font-size: 20px;
    }
  }

  .footer {
    //background-image: url('../assets/info-background.svg');
    background-repeat: no-repeat;
    height: 320px;
  }
}

@media (min-width: 480px) {
  .info-page {
    .content {
      padding: 40px 40px 0;
    }

    .kendoka {
      img {
        max-width: 348px;
      }
    }

    .section-1 {
      h1 {
        font-size: 71px;
      }

      h2 {
        font-size: 38px;
      }

      .button-group {
        .k-button {
          display: inline-block;
          margin-right: 10px;
          width: auto;
        }
      }
    }

    .section-3 {
      -webkit-columns: 3;
      column-count: 3;
    }
  }
}

@media (min-width: 768px) {
  .info-page {
    .content {
      padding: 120px 40px 0;
    }

    .kendoka {
      position: absolute;
      right: 0;
      top: 2em;
      text-align: left;
    }

    .section-1,
    .section-2 {
      text-align: left;
      padding: 0;
    }

    .section-3 {
      -webkit-columns: 4;
      column-count: 4;
      padding: 0;
    }

    .footer {
      background-size: cover;
    }
  }
}

// src\ticketing\components\bulkImport\BulkImportTickets.tsx Starts

.bulk-import-tickes-card-container {
  max-width: 1024px;
  margin: auto;
  padding: 1rem;
  display: flex;
  gap: 10px;

  .ticket-files-list-wrap {
    border: 1px solid gray;
    background-color: rgb(48, 48, 48);
  }

  .ticket-files-list-header {
    text-align: center;
    padding: 0.25rem;
  }

  .ticket-files-list {
    padding: 0.25rem;
    color: rgb(189, 189, 189);
    list-style-position: inside;
    margin: 0.25rem;
  }

  .ticket-files-list li::before {
    content: "";
    padding: 0;
    margin: 0;
  }

  .ticket-files-list-item-text {
    cursor: pointer;
    color: #ff6358;
    font-size: 0.85rem;
  }
}

.bulk-import-multi-column .k-table-sm {
  font-size: 0.75rem;
}

.bulk-tickets-cta-btn {
  min-width: 96px;
}

// src\ticketing\components\bulkImport\BulkImportTickets.tsx Ends

.bulk-import-cell-input {
  width: 100%;
  font-size: 0.75rem;
  padding: 1px;
  border: 0;
}

.ellipsis-container {
  width: 200px; /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
