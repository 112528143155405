.roles-page,
.users-page {
  border-top: 4px solid gray;
  display: flex;
  font-size: 0.75rem !important;
}

.roles-page .card-container .k-grid,
.users-page .card-container .k-grid {
  font-size: 0.75rem;
}

.roles-page .k-button,
.users-page .k-button {
  font-size: 0.75rem;
  /* background-color: rgb(255, 99, 98);
      color: #fff */
}

.users-container,
.roles-container {
  display: flex;
  padding: 2px;
  gap: 16px;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.users-container .grid-container,
.roles-container .grid-container {
  border: 1px solid black;
}

.user-edit-container,
.role-edit-container {
  min-width: 27rem;
  /* background-color: #323232; */
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}

.users-container .k-table,
.roles-container .k-table {
  font-size: 0.75rem;
}

.user-edit-container .k-tool-bar,
.role-edit-container .k-tool-bar {
  /* background-color: #000033;
  border-bottom: 1px solid #575499; */
  background-color: var(--modal-title-bg);
  border-bottom: 1px solid var(--modal-title-border);
  color: var(--modal-icon);
  border-radius: 5px 5px 0 0;
  height: 30px;
}

.user-edit-container .k-form {
  border: 1px solid var(--modal-title-border);
  border-radius: 5px;
}

.user-edit-container .k-form,
.user-edit-container .k-textbox,
.role-edit-container .k-form,
.role-edit-container .k-textbox {
  font-size: 0.75rem;
}

.user-edit-container .k-label,
.role-edit-container .k-label {
  color: silver;
}

.user-edit-container .k-button,
.role-edit-container .k-button {
  margin: 2px;
  height: 25px;
}

.user-edit-container .k-treeview .k-in,
.role-edit-container .k-treeview .k-in {
  padding: 1px;
}

.user-edit-container .k-treeview,
.user-edit-container .k-content,
.role-edit-container .k-treeview,
.role-edit-container .k-content {
  /* background-color: #2d3436; */
  color: var(--form-field-txt-color);
  font-size: 0.75rem;
  /* #2d3436; */
}

.roles-tree-container,
.privileges-tree-container {
  max-height: calc(100vh - 312px);
  overflow-y: auto;
}

.user-edit-container .k-form-field > .k-label {
  color: var(--card-text-color);
}
.user-edit-container .k-input-solid {
  background-color: var(--form-field-bg-color);
  color: var(--form-field-txt-color);
  border: 1px solid var(--form-field-border-color);
  outline: none;
}
.user-edit-container .k-input-solid:hover {
  border-color: var(--form-field-border-hover-color);
}
