.edit-ticket-modal {
  padding: 0px;
  background-color: azure;
}

.edit-ticket-modal .k-window-titlebar {
  padding: 8px;
}

.edit-ticket-modal .k-window-content {
  background-color: #555;
  height: 100%;
}

.edit-ticket-modal .k-window-content .card-container {
  height: 100%;
}

.edit-ticket-modal .k-window-content .card-container .action-bar {
  display: flex;
  padding: 5px;
  height: 40px;
  gap: 1rem;
  font-size: 0.85rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.edit-ticket-modal .ticket-header {
  display: flex;
  padding: 5px;
  font-size: 0.85rem;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
}

.ticket-volume-grid-wrapper {
  display: grid;
  grid-template-columns: minmax(120px, auto) repeat(3, minmax(96px, auto)) 30px 30px;
  padding: 10px;
  column-gap: 10px;
  row-gap: 5px;
  margin: 10px;
  align-items: center;
  border-radius: 5px;
}

.ticket-volume-grid-wrapper label {
  text-align: center;
  /* font-weight: bold !important; */
  font-size: small !important;
  position: relative;
}

.warning-span {
  position: absolute;
  top: -1px;
  right: 20px;
}

.ticket-volume-grid-wrapper .k-picker {
  font-size: small;
}

.ticket-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.ticket-wrapper > div:nth-child(even) {
  background-color: #71706e;
}

.na-product-wrapper {
  margin: 2px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid var(--modal-title-border);
}

.na-product-wrapper .k-invalid {
  border: 2px solid red;
}

.na-product-wrapper label {
  white-space: nowrap;
  font-size: small;
  color: var(--modal-title-txt);
  user-select: none;
}

.na-product-wrapper .field {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
}

.na-product-wrapper .k-input {
  font-size: small;
}

.na-product-wrapper .ticket-details {
  display: grid;
  gap: 4px;
  padding: 4px;
  grid-template-columns: 108px 1fr auto 108px 1fr auto;
  justify-items: right;
  align-items: center;
  /* margin: 10px; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.na-product-wrapper .ticket-details-wrapper {
  border: 1px solid var(--modal-title-border);
  border-radius: 5px;
}

.na-product-wrapper .ticket-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  background-color: var(--modal-title-bg);
  border-bottom: 1px solid var(--modal-title-border);
  color: var(--modal-title-txt);
}

.show-document {
  gap: 0;
}

.show-document .k-icon {
  margin-left: 0;
  order: 2;
}

.pdf-component {
  padding: 4px;
  /* border: 1px solid white; */
  box-sizing: border-box;
  /* flex: 1 1 auto; */
}

.pdf-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
}

.pdf-component-header label {
  font-size: small !important;
  font-weight: normal !important;
}

.pdf-component .document-page-controls-container {
  display: flex;
  margin: 5px;
  gap: 5px;
}

.pdf-component .document-page-controls {
  display: flex;
  padding: 5px;
  background-color: #555;
  color: whitesmoke;
  border-radius: 5px;
  gap: 5px;
  align-items: center;
}
