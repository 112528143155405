.k-datepicker.k-datepicker.k-datepicker {
  border-color: var(--form-field-border-color);
  background-color: var(--form-input-bg);
  color: var(--form-field-txt-color);

  &:hover {
    border-color: var(--form-field-border-hover-color);
  }

  .k-input-button {
    background-color: var(--form-input-bg);
    color: var(--form-input-icon);
  }
}

.nav-tab {
  background-color: var(--nav-bg);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px, rgba(0, 0, 0, 0.1) 0px 0px 2px;
  .inactive-tab {
    background-color: var(--nav-bg);
    color: var(--nav-txt);
    &:hover {
      background-color: var(--nav-selected-bg);
    }
  }
  .active-tab {
    background-color: var(--nav-selected-bg);
    color: var(--nav-txt);
  }
}

.k-form-field-wrap.k-form-field-wrap.k-form-field-wrap.k-form-field-wrap {
  color: var(--form-input-color);
}

.k-floatwrap.k-floatwrap.k-floatwrap,
.theme-form-input.theme-form-input {
  background-color: var(--form-field-bg-color);
  color: var(--form-field-txt-color);
  border-color: var(--form-field-border-color);
  border-style: solid;
  border-width: 1px;
  outline: none;
  &:hover:hover,
  &:focus {
    border-color: var(--form-field-border-hover-color);
  }
}

.theme-btn-green.theme-btn-green.theme-btn-green {
  background-color: var(--forest-500);
  border: 1px solid transparent;
  cursor: pointer;
  max-width: 100%;
  &,
  * {
    color: var(--white);
  }
}

.theme-btn-red.theme-btn-red.theme-btn-red {
  background-color: var(--red-500);
  border: 1px solid transparent;
  cursor: pointer;
  max-width: 100%;
  &,
  * {
    color: var(--white);
  }
}

.theme-btn-yellow.theme-btn-yellow.theme-btn-yellow {
  background-color: var(--primary-btn-bg);
  border: 1px solid transparent;
  cursor: pointer;
  max-width: 100%;
  &,
  * {
    color: var(--primary-btn-txt);
  }
}

.k-input-inner {
  background-color: var(--form-field-bg-colorD);
}

.k-chip-solid-base {
  background-color: var(--multiselect-dropdown-badge-bg);
  color: var(--multiselect-dropdown-badge-txt);
  &:hover {
    background-color: var(--multiselect-dropdown-badge-hover-bg);
  }
  .k-chip-actions {
    color: var(--multiselect-dropdown-badge-icon);
  }
}

.k-list {
  background-color: var(--multiselect-dropdown-bg);
  border-color: transparent;
  color: var(--multiselect-dropdown-txt);
}

.k-list-item {
  &:hover {
    background-color: var(--multiselect-dropdown-hover);
    color: var(--multiselect-dropdown-txt);
  }
  &.k-selected {
    background-color: var(--multiselect-dropdown-selected-bg);
    color: var(--multiselect-dropdown-selected-txt);
    &:hover {
      background-color: var(--multiselect-dropdown-selected-hover);
    }
  }
}

.k-list-container {
  border: none;
  box-shadow: var(--multiselect-dropdown-shadow) 0px 6px 24px,
    var(--multiselect-dropdown-shadow) 0px 2px 6px;
}

.k-switch-off .k-switch-track {
  border-color: var(--toggle-off-bg);
  background-color: var(--toggle-off-bg);
  color: var(--toggle-off-txt);
}

.k-switch-on .k-switch-track {
  border-color: var(--toggle-on-bg);
  background-color: var(--toggle-on-bg);
  color: var(--toggle-on-txt);
}

.k-switch-off,
.k-switch-on {
  &:focus .k-switch-track,
  &.k-focus .k-switch-track {
    outline: 2px solid var(--toggle-on-bg);
  }
}
