.parent {
  display: grid;
  grid-template-columns:
    88px minmax(120px, 1fr) 88px minmax(120px, 1fr) 88px minmax(120px, 1fr)
    100px;
  /* grid-template-rows: repeat(4, 0.5fr); */
  /* grid-template-rows: fit-content(24px); */
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  margin-top: 2px;
  margin-left: 2px;
  align-items: center;
  /* width: 100%; */
}

.parent label {
  min-width: 80px;
  color: var(--form-field-txt-color);
  /* font-size: 0.75rem; */
}

.parent input {
  min-width: 96px;
  /* font-size: 0.75rem; */
}

.parent button {
  max-width: 96px;
  /* font-size: 0.75rem; */
}

/* .parent .k-chip {
  font-size: 0.75rem;
}
.parent .k-list-sm {
  font-size: 0.75rem !important;
}
.parent .k-list-content {
  font-size: 0.75rem;
} */

.m-popup .k-list-item {
  font-size: 0.75rem;
}
