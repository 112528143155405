.forecast-export-settings {
  dl,
  ol,
  ul {
    margin-top: 0;
  }

  .k-dialog {
    border: 1px solid #e0e0e0;
    font-size: 0.75rem;
    color: var(--modal-content-txt);
  }

  .k-dialog-titlebar {
    background-color: var(--modal-title-bg);
    color: var(--modal-icon);
    border: 0;
    padding: 0.5rem;
  }

  .k-dialog-title {
    font-size: 1rem;
    color: var(--modal-title-txt);
  }

  .k-dialog-content {
    padding: 4px;
  }

  .k-dialog-actions {
    background-color: var(--nav-bg);
    color: #ff6358;
    border-top-color: var(--modal-content-border);
    border-top-width: 1px;
  }

  .k-picker,
  .k-label {
    font-size: 0.75rem;
    border: 1px;
  }

  .k-animation-container {
    z-index: 10003;
  }

  .payload {
    border-top: 1px solid var(--modal-content-border);
    background-color: var(--nav-bg);
  }

  .start-date-of .k-textbox-container > .k-label,
  .start-date-of .k-floating-label-container > .k-label,
  .plus-months .k-textbox-container > .k-label,
  .plus-months .k-floating-label-container > .k-label,
  .time-zone .k-textbox-container > .k-label,
  .time-zone .k-floating-label-container > .k-label {
    color: var(--form-input-label-color);
  }

  .start-date-of .k-input,
  .start-date-of .k-list-title,
  .start-date-of .k-list-container > .k-popup,
  .start-date-of .k-icon-button,
  .plus-months .k-input,
  .plus-months .k-list-title,
  .plus-months .k-list-container > .k-popup,
  .plus-months .k-icon-button,
  .time-zone .k-input,
  .time-zone .k-list-title,
  .time-zone .k-list-container > .k-popup,
  .time-zone .k-icon-button {
    font-size: 0.75rem;
  }

  .start-date-of .k-picker {
    width: 12rem;
  }

  .plus-months .k-input {
    width: 4rem;
  }

  .time-zone .k-picker {
    width: 12rem;
  }

  .c-host {
    background-color: var(--nav-bg);
  }

  .c-tab-content {
    height: 144px;
  }

  .cron-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .cron-nav-item {
      .cron-nav-link {
        cursor: pointer;
      }
    }
  }

  .cron-nav-tabs {
    box-shadow: var(--black-100-A) 0 2px 5px, var(--black-100-A) 0 0 2px;

    .cron-nav-link {
      background-color: var(--nav-bg);
      min-width: 72px;
      border: 1px solid var(--nav-bg);
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: var(--nav-txt);
      display: block;
      padding: 0.5rem 1rem;
    }

    .cron-active {
      color: var(--nav-txt);
      box-shadow: 0 -3px 0 0 var(--nav-selected-border) inset;
      background-color: var(--nav-selected-bg);
    }
  }

  .cron-row {
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .cron-col-1 {
      width: calc(100% / 12);
      float: left;
    }

    .cron-col-2 {
      width: calc(100% / 8);
      float: left;
    }

    .cron-col-3 {
      width: calc(100% / 4);
      float: left;
    }
  }

  .cron-form-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cron-form-group {
    margin-bottom: 0.85rem;

    .c-increment-option-label2 {
      color: white;
    }

    .cron-form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      margin: 0 0.2rem;
    }

    .cron-form-control-sm {
      /* height: calc(1.8125rem + 2px); */
      padding: 0.05rem 0.25rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }

    .cron-form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      width: auto;
      padding-left: 0;
      position: relative;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .cron-form-check-label {
      padding-left: 0.4rem;
      display: flex;
      align-items: center;
      color: var(--checkbox-label-txt);
    }

    .cron-form-check-input {
      border-color: rgba(0, 0, 0, 0.08);
      color: transparent;
      background-color: #bebebe;
      border-radius: 2px;
      margin: 0;
      padding: 0;
      width: 12px;
      height: 12px;
      line-height: initial;
      border-width: 1px;
      border-style: solid;
      outline: 0;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      -webkit-appearance: none;
    }

    .cron-form-check-input:checked {
      border-color: #ff6358;
      color: #ffffff;
      background-color: #ff6358;
    }

    .cron-form-check-input:checked::before,
    .cron-form-check-input.k-checked::before {
      border-radius: 2px;
      transform: scale(1) translate(-50%, -50%);
    }

    .cron-form-check-input::before {
      line-height: 1;
      transform: scale(0) translate(-50%, -50%);
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      font-family: "WebComponentsIcons", monospace;
      font-size: 10px;
    }

    .cron-form-check-input:disabled,
    .cron-form-check-input:disabled + .cron-form-check-label {
      outline: none;
      cursor: default;
      opacity: 0.6;
      filter: grayscale(0.1);
      pointer-events: none;
      box-shadow: none;
    }
  }

  .cron-mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .cron-ml-1 {
    margin-left: 0.25rem !important;
  }

  .cron-mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .cron-pl-3 {
    padding-left: 0.75rem !important;
  }

  .cron-px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .cron-pt-1 {
    padding-top: 0.25rem !important;
  }

  .c-increment-weekday,
  .c-increment-monthday,
  .c-and-monthday,
  .c-last-nth,
  .c-nth,
  .c-nearest,
  .c-day-before-end,
  .c-range {
    display: none;
  }
}
